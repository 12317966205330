package com.kelimesoft.etutpro.viewmodels

import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.network.FaaliyetOzetResponse
import com.kelimesoft.etutpro.network.HedefListResponse
import com.kelimesoft.etutpro.network.RestApi
import com.kelimesoft.etutpro.utils.Funcs
import com.kelimesoft.etutpro.utils.addDays
import com.kelimesoft.etutpro.utils.dateToDbStr
import kotlinx.serialization.json.Json
import kotlin.js.Date

class OzetVM: BaseVM() {
    var weekStartDate by mutableStateOf(getWeekStartDay(Date()))
    var hedefList = listOf<Hedef>()

    var ayDersMap by mutableStateOf(listOf<DersMap>())
    var haftaDersMap by mutableStateOf(listOf<DersMap>())
    var gunDersMap by mutableStateOf(listOf<DersMap>())
    var aySayfa by mutableStateOf(0)
    var ayScore by mutableStateOf(0.0)

    var haftaSayfa by mutableStateOf(0)
    var haftaScore by mutableStateOf(0.0)
    var gunSayfa by mutableStateOf(0)
    var gunScore by mutableStateOf(0.0)
    var ayKitapList by mutableStateOf(listOf<KitapOzet>())
    var haftaKitapList by mutableStateOf(listOf<KitapOzet>())
    var gunKitapList by mutableStateOf(listOf<KitapOzet>())
    var ayDeneme by mutableStateOf(listOf<DenemeData>())
    var haftaDeneme by mutableStateOf(listOf<DenemeData>())
    var gunDeneme by mutableStateOf(listOf<DenemeData>())


    suspend fun getFaaliyetOzet(uuid: String? = null) {
        RestApi.getFaliyetOzet(weekStartDate.dateToDbStr(), uuid)?.let { res ->
            //console.log("faaliyetres:", res)
            Json.decodeFromString<FaaliyetOzetResponse>(res).let { fres ->
                if (fres.data != null) {
                    ayDersMap = fres.data.ay?: listOf()
                    haftaDersMap = fres.data.hafta ?: listOf()
                    gunDersMap = fres.data.gun ?: listOf()
                    ayScore = fres.data.apuan
                    haftaScore = fres.data.hpuan
                    gunScore = fres.data.gpuan
                    aySayfa = fres.data.asyf
                    haftaSayfa = fres.data.hsyf
                    gunSayfa = fres.data.gsyf
                    ayKitapList = fres.data.aklist
                    haftaKitapList = fres.data.hklist
                    gunKitapList = fres.data.gklist
                    ayDeneme = fres.data.adene
                    haftaDeneme = fres.data.hdene
                    gunDeneme = fres.data.gdene
                }
            }
        }
    }

    private fun getWeekStartDay(date: Date): Date {
        var firstweekday = date.getDay()
        if (firstweekday == 0) firstweekday = 7
        return date.addDays(-(firstweekday) + 1)
    }


    suspend fun getHedefList(uuid: String? = null) {
        RestApi.getHedefList(uuid)?.let { res ->
            //console.log("hedefres:", res)
            Json.decodeFromString<HedefListResponse>(res).let { hres ->
                if (hres.data != null) {
                    hedefList = hres.data

                } else if (hres.error != null) {

                }
            }
        }
    }


    suspend fun addNewHedef(hedef: Hedef, uuid: String? = null): FuncResult {
        if (!Funcs.checkForm("hedef-form")) {
            return FuncResult(false, "Formu eksiksiz doldurunuz!")
        }
        RestApi.addNewHedef(hedef, uuid)?.let { res ->
            Json.decodeFromString<HedefListResponse>(res).let { hres ->
                if (hres.data != null) {
                    hedefList = hres.data
                    return FuncResult(true)
                } else if (hres.error != null) {
                    return FuncResult(false, hres.error)
                }
            }
        }
        return FuncResult(false, "Connection Error")
    }


}