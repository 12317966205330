package com.kelimesoft.etutpro.models

import kotlinx.serialization.Serializable

@Serializable
data class FaaliyetOzet(
    val ay: ArrayList<DersMap>?,
    val hafta: List<DersMap>? = listOf(),
    val gun: List<DersMap>? = listOf(),
    val apuan: Double,
    val hpuan: Double,
    var gpuan: Double,
    val asyf: Int,
    val hsyf: Int,
    val gsyf: Int,
    val aklist: ArrayList<KitapOzet>,
    val hklist: ArrayList<KitapOzet>,
    val gklist: ArrayList<KitapOzet>,
    val adene: ArrayList<DenemeData>,
    val hdene: ArrayList<DenemeData>,
    val gdene: ArrayList<DenemeData>,

    )

@Serializable
data class KitapOzet(
    val kitap: String,
    val sayfa: Int
)

@Serializable
data class DenemeData(
    val deneme: String,
    val soru: Int = 0,
    val dogru: Int = 0,
    val yanlis: Int = 0,
    val net: Double = 0.0,
    val tarih: String = ""

){
    val score: Int
        get() = if (soru > 0) (100 * net).toInt() / soru else 0

}

@Serializable
data class DersMap(
    val ders: String,
    val konulist: List<KonuMap>? = listOf(), //seçim
)

@Serializable
data class KonuMap(
    val konu: String,
    var soru: Int,
    val dogru: Int = 0,
    val yanlis: Int = 0,
    val net: Double = 0.0,
)