package com.kelimesoft.etutpro.components

import androidx.compose.runtime.*
import com.kelimesoft.etutpro.models.*
import com.kelimesoft.etutpro.styles.AppBarButtonStyle
import com.kelimesoft.etutpro.styles.NavTextStyle
import com.kelimesoft.etutpro.styles.SnfKatilButtonStyle
import com.kelimesoft.etutpro.utils.dateDiff
import com.kelimesoft.etutpro.utils.round
import com.stevdza.san.kotlinbs.components.BSButton
import com.stevdza.san.kotlinbs.components.BSTooltip
import com.stevdza.san.kotlinbs.components.initializeTooltips
import com.stevdza.san.kotlinbs.models.TooltipDirection
import com.stevdza.san.kotlinbs.models.button.ButtonSize
import com.stevdza.san.kotlinbs.models.button.ButtonVariant
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaAward
import com.varabyte.kobweb.silk.components.icons.fa.FaBookOpen
import com.varabyte.kobweb.silk.components.icons.fa.FaUsers
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.js.Date


@Composable
fun MyNavBar(page: Screens, onSelect: () -> Unit,
             addOgrenci: () -> Unit, onSnf: () -> Unit) {
    val breakpoint = rememberBreakpoint()
    val context = rememberPageContext()
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    var showSnfInfo by remember { mutableStateOf(false) }
    var showLgsDate by remember { mutableStateOf(false) }

    LaunchedEffect(true, ogrenciVM.selectedOgrenci){
        var userSnf = 0
        val user = AppData.appUser
        if (user.role == 0 && user.snfid > 0){
            showSnfInfo = true
            userSnf = user.snf
        }else if(user.role == 2){
            ogrenciVM.selectedOgrenci?.let {
                if(it.snfid > 0) {
                    showSnfInfo = true
                }
                userSnf = it.snf

            }
        }
        showLgsDate = userSnf == 8
    }

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .backgroundColor(AppColors.AppBarColor)
            .borderRadius(r = 12.px)

    ) {
        Column(
            modifier = Modifier.fillMaxWidth()
                .padding(6.px)
                .backgroundImage(AppColors.GradientPrimary)
                .borderRadius(r = 12.px)

        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            ) {

                AppBrand()
                if (breakpoint > Breakpoint.SM) {
                    MenuButtons(context, 16)
                }
                Spacer()

                if (page == Screens.Etut) {
                    if (showSnfInfo){
                        SnfScoreView()
                    }else{
                        snfKatilButon(){
                            onSnf()
                        }
                    }
                    KitapReadingInfo()
                    AwardInfo()
                }else if(showLgsDate){
                    LgsDateView()
                }

                UserInfo(breakpoint)
            }
            if (breakpoint <= Breakpoint.SM) {
                MenuButtons(context, 14)
            }
            /*
            if (AppData.appUser.role == 0 && page == Screens.Ozet){
                if (AppData.appUser.scode.isNotEmpty() || AppData.appUser.vcode.isNotEmpty()){
                    AddOgrenciInfo()
                }
            }else */
            if (AppData.appUser.role == 2) {
                VDivider(modifier = Modifier.margin(bottom = 2.px))
                OgrenciListView(breakpoint, onSelect = {
                    onSelect()
                }, addOgrenci = {
                    addOgrenci()
                })
            }

        }
    }
}


@Composable
fun AppBrand() {
    val context = rememberPageContext()
    Row(
        modifier = Modifier.margin(right = 20.px)
            .cursor(Cursor.Pointer).onClick {
                AppData.appRoutes.navigateTo(context, Screens.Home)
            }
        ,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Image(
            modifier = Modifier
                .margin(bottom = 2.px)
                .size(22.px),
            src = Images.AppIcon,
            alt = "etut pro ile okulda başarı"

        )
        SpanText(
            "EtütPro", modifier = Modifier.color(AppColors.ViewWhite)
                .margin(left = 4.px)
                .fontSize(22.px)
        )

    }

}


@Composable
fun snfKatilButon(onClick: () -> Unit){
    val breakpoint = rememberBreakpoint()
    Button(
        attrs = SnfKatilButtonStyle.toModifier()
            .borderRadius(r = 8.px)
            .margin(right = 8.px)
            .onClick {
                onClick()
            }
            .toAttrs()
    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            FaUsers(size = IconSize.SM)
            Span(
                attrs = Modifier
                    .margin(leftRight = 3.px)
                    .padding(topBottom = 2.px)
                    //.color(AppColors.ViewWhite)
                    .fontSize(14.px).toAttrs()
            ) {
                Text(if (breakpoint > Breakpoint.SM) "Sınıfa Katıl" else "+")
            }

        }

    }
}

@Composable
fun MenuButtons(context: PageContext, size: Int) {
    Row(verticalAlignment = Alignment.CenterVertically) {
        AppBarMenuButton(
            "Özet", Images.Ozet,
            AppData.appRoutes.activeRoute == Screens.Ozet, size
        ) {

            AppData.appRoutes.navigateTo(context, Screens.Ozet)
        }
        /*
        AppBarMenuButton(
            "Ders Programı", Images.Ders,
            AppData.appRoutes.activeRoute == Screens.Ders, size
        ) {
            AppData.appRoutes.navigateTo(context, Screens.Ders)
        }*/
        AppBarMenuButton(
            "Etüt Planı", Images.Etut,
            AppData.appRoutes.activeRoute == Screens.Etut, size
        ) {
            AppData.appRoutes.navigateTo(context, Screens.Etut)
        }
    }
}

@Composable
fun AppBarMenuButton(
    title: String, image: String,
    selected: Boolean = false,
    size: Int,
    onSelect: () -> Unit
) {
    Row(modifier = Modifier

        .onClick {
            onSelect()
        }
        .cursor(Cursor.Pointer)
        .margin(left = 20.px),
        verticalAlignment = Alignment.CenterVertically) {
        Image(
            modifier = Modifier
                .size(14.px),
            src = image,
            alt = "etut pro ile okulda başarı"
        )
        SpanText(
            title, modifier = NavTextStyle.toModifier()
                .color(AppColors.ViewWhite.opacity(if (selected) 1.0f else 0.5f))
                .margin(left = 4.px)
                .fontSize(size.px)
        )
    }
}


@Composable
fun OgrenciListView(breakpoint: Breakpoint,
                    onSelect: (OgrenciInfo) -> Unit,
                    addOgrenci: () -> Unit
                    ) {
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    Column(
        modifier = Modifier.fillMaxWidth()
            .color(AppColors.ViewWhite.opacity(0.7f))
            .padding(leftRight = 4.px)
    ) {
        if (breakpoint < Breakpoint.SM) {
            SpanText("Öğrencilerim: ")
        }

        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        ) {
            if (breakpoint >= Breakpoint.SM) {
                SpanText("Öğrencilerim: ")
            }
            SimpleGrid(numColumns = numColumns(base = 2, sm = 3, md = 5, lg = 6), modifier = Modifier.fillMaxWidth()) {
                ogrenciVM.ogrenciListem.forEach { ogrenci ->
                    OgrenciRow(ogrenci, ogrenci == ogrenciVM.selectedOgrenci) {
                        if (ogrenci != ogrenciVM.selectedOgrenci) {
                            ogrenciVM.selectOgrenci(ogrenci)
                            AppData.ViewModel.kaynakVM.allKaynakList = listOf()
                            AppData.ViewModel.dersVM.dersList = setOf()
                            AppData.ViewModel.dersVM.dersProgramList = listOf()
                            AppData.ViewModel.etutVM.allEtutList = listOf()
                            onSelect(ogrenci)
                        }
                    }
                }
            }
            BSButton(
                modifier = Modifier.maxHeight(26.px)
                    .textOverflow(TextOverflow.Ellipsis)
                    .borderRadius(r = 10.px)
                    .margin(left = 4.px),
                text = "+Ekle", size = ButtonSize.Small,
                variant = ButtonVariant.LightOutline
            ) {
                addOgrenci()
            }

        }
    }

}


@Composable
fun UserInfo(breakpoint: Breakpoint) {
    val context = rememberPageContext()

    fun getImage(): String {
        when(AppData.appUser.role){
            0 -> return Images.OgrenciW
            1 -> return Images.OgrenciW
            2 -> return Images.VeliW

        }
        return Images.Ogrenci
    }
    Row(
        modifier = Modifier
            .backgroundColor(AppColors.AppBarButonColor)
            .borderRadius(r = 16.px)
            .margin(2.px)
            .minHeight(48.px)
            .padding(4.px),
        verticalAlignment = Alignment.CenterVertically
    ) {

        Button(
            attrs = AppBarButtonStyle.toModifier()
                .margin(left = 8.px)
                .borderRadius(r = 8.px)
                .onClick {
                    AppData.appRoutes.navigateTo(context, Screens.Profil)
                }
                .toAttrs()
        ) {
            Row(verticalAlignment = Alignment.CenterVertically) {
                Image(
                    modifier = Modifier
                        .margin(bottom = 2.px)
                        .margin(right = 6.px)
                        .size(22.px),
                    src = getImage(),
                    alt = "etüt yapan başarılı öğrenci profili"

                )
                if (breakpoint > Breakpoint.SM) {
                    Span(
                        attrs = Modifier.color(AppColors.ViewWhite)
                            .fontSize(16.px).toAttrs()
                    ) {
                        Text(AppData.appUser.uname)
                    }

                }
            }

        }



        Button(
            attrs = AppBarButtonStyle.toModifier()
                .margin(left = 8.px)
                .onClick {
                    AppData.logOut(context)
                }
                .borderRadius(r = 8.px)
                .toAttrs()
        ) {
            FaSignOut(size = IconSize.XL, modifier = Modifier.color(AppColors.DarkRed))
        }

    }
}


@Composable
fun AwardInfo() {
    BSTooltip("Toplam haftalık puanınız", direction = TooltipDirection.Bottom) {
        Column(
            modifier = Modifier.borderRadius(r = 50.percent)
                .size(48.px)
                .minSize(44.px)
                .margin(right = 10.px)
                .backgroundColor(AppColors.AppBarButonColor),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            FaAward(
                size = IconSize.LG,
                modifier = Modifier.color(AppColors.AwardColor)
            )
            Span(
                attrs = Modifier
                    .margin(top = 10.px)
                    .color(AppColors.BallonYellow.opacity(0.8f))
                    .fontWeight(FontWeight.Medium)
                    .fontSize(15.px).toAttrs()
            ) {
                Text(AppData.ViewModel.etutVM.userAward.toString())
            }
        }
    }


}


@Composable
fun KitapReadingInfo() {
    //Row(verticalAlignment = Alignment.CenterVertically) {
        Column(
            modifier = Modifier.borderRadius(r = 50.percent)
                .size(48.px)
                .minSize(48.px)
                .margin(right = 10.px)
                .backgroundColor(AppColors.AppBarButonColor),
            verticalArrangement = Arrangement.Bottom,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            FaBookOpen(
                size = IconSize.LG,
                modifier = Modifier.color(AppColors.Gray)
            )
            Span(
                attrs = Modifier
                    .margin(top = 10.px)
                    .color(AppColors.BallonYellow.opacity(0.8f))
                    .fontWeight(FontWeight.Medium)
                    .fontSize(15.px).toAttrs()
            ) {
                Text(AppData.ViewModel.etutVM.kitapSayfa.toString())
            }
        }
    //}


}

@Composable
fun OgrenciRow(ogrenci: OgrenciInfo, selected: Boolean, onSelect: () -> Unit) {
    fun getSnf(): String {
        if (ogrenci.snf == 13) return " (Mezun)"
        else if (ogrenci.snf == 20) return " (Ünv.)"
        else if (ogrenci.snf == 0) return " (O.Ö.)"
        else return " (${ogrenci.snf}.Snf)"
    }
    Row(modifier = Modifier
        .maxWidth(160.px)
        .textOverflow(TextOverflow.Ellipsis)
        .margin(left = 6.px, bottom = 2.px)
        .borderRadius(r = 8.px)
        .onClick {
            onSelect()
        }
        .thenIf(selected) {
            Modifier.border(1.px, color = AppColors.ViewWhite, style = LineStyle.Solid)
                .color(AppColors.ViewWhite)
        }
        .padding(leftRight = 6.px, topBottom = 2.px)
        .cursor(Cursor.Pointer)
        .backgroundColor(AppColors.AppBarButonColor),
        verticalAlignment = Alignment.CenterVertically) {
        Image(
            src = Images.AppIcon,
            modifier = Modifier.size(20.px)
                .margin(left = 2.px)
        )

        Span(attrs = Modifier.fontSize(13.px).toAttrs()) {
            Text(ogrenci.name)
            Span(attrs = Modifier.fontSize(10.px).toAttrs()) { Text(getSnf()) }
        }


    }
}


@Composable
fun SnfScoreView() {
    LaunchedEffect(true) {
        initializeTooltips()
    }
    val etutVM = AppData.ViewModel.etutVM
    val ogrenciVM = AppData.ViewModel.ogrenciVM
    val user = AppData.appUser
    LaunchedEffect(true, ogrenciVM.selectedOgrenci){
        var snfId = user.snfid
        if (user.role == 2){
            ogrenciVM.selectedOgrenci?.let {
                snfId = it.snfid
            }
        }
        if (snfId > 0) {
            etutVM.getSinifScore(snfId)
        }
    }

    BSTooltip("Sınıfınızın Puan ortalaması", direction = TooltipDirection.Bottom) {
            Column(
                modifier = Modifier.borderRadius(r = 50.percent)
                    .size(48.px)
                    .minSize(48.px)
                    .margin(right = 10.px)
                    .backgroundColor(AppColors.AppBarButonColor),
                verticalArrangement = Arrangement.Bottom,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                FaUsers(
                    size = IconSize.SM,
                    modifier = Modifier.color(AppColors.WeekEndColor)
                )
                Span(
                    attrs = Modifier
                        .margin(top = 10.px)
                        .color(AppColors.BallonYellow.opacity(0.8f))
                        .fontWeight(FontWeight.Medium)
                        .fontSize(14.px).toAttrs()
                ) {
                    Text(etutVM.snfScore.round(1))
                }
            }
        }

}


@Composable
fun LgsDateView() {
    val lgsDate = Date("2025-06-15")
    val kalanGun = dateDiff(lgsDate, Date(), "d")

    Column(modifier = Modifier
        .fontSize(0.7.cssRem)
        .color(AppColors.ViewWhite)
        .padding(leftRight = 6.px, topBottom = 2.px)
        .backgroundColor(AppColors.TextColor)
        .borderRadius(r = 6.px)
        .margin(right = 6.px)
    ) {
        Span {
            Text("LGS Sınavı: ")
            Span(attrs = Modifier
                .textAlign(TextAlign.End)
                .fontWeight(FontWeight.SemiBold)
                .toAttrs()){
                Text(lgsDate.toLocaleDateString())
            }

        }
        Span(attrs = Modifier
            .color(AppColors.Red)
            .toAttrs()) {
            Text("Kalan Gün: ")
            Span(attrs = Modifier
                .textAlign(TextAlign.End)
                .fontWeight(FontWeight.SemiBold)
                .toAttrs()){
                Text(kalanGun.toString())
            }

        }
    }
}